/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* For Quill JS */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "ag-grid-community/styles/ag-theme-material.css";
@import "ag-grid-community/styles/ag-theme-balham.css";

* {

    -webkit-user-select: text;

    -moz-user-select: text;

    -ms-user-select: text;

    user-select: text;

}

@media (max-width: 972px) {
    .unifiedStyle {
        min-height: 600px;
    }

    .unifiedSecondaryStyle {
        display: none !important;
    }
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'roboto', sans-serif;
}

h6,
p,
span,
a {
    font-family: 'roboto', sans-serif;
}

.support-messages p {
    margin: 0;
    font-family: 'roboto', sans-serif;
    font-weight: 500;
    font-size: 18px !important;
}

.flex-center-y {
    display: flex;
    justify-content: center;
    align-items: center;
}

.xy-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#sentProposal {
    p {
        margin: 0;
    }
}

.slide-out-modal::part(content) {
    width: 90%;
    height: 100%;
    position: absolute;
    right: 0;
    background-color: transparent;
    box-shadow: none;
}

:root {
    //font-family: "Poppins", sans-serif;
    font-family: 'roboto', sans-serif;
    font-family: 'roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'roboto', sans-serif;
}

h6,
p {
    font-family: 'roboto', sans-serif;
}

.swiper-wrapper {
    align-items: center;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #8180e02c;
    margin: 40px 0px;

    &.short {
        margin: 20px 0px;
    }
}

// .app-container {
//     margin-left: 250px;
// }

@media (max-width: 720px) {
    .app-container {
        margin: 0px;
    }
}

.step-container {
    display: flex;

    .left {
        width: 40px;
    }

    .right {
        flex: 1;
        padding-left: 20px;
        text-align: center;

        &.nopadding {
            padding-left: 0px;
        }

        p.step-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 0.8rem;
            font-family: 'roboto', sans-serif;
            color: grey;
            height: 40px;
            margin: 0;
            flex: 1;
            text-align: center;
        }

        form {
            width: 100%;

            .input-container {
                width: 100%;
                height: 47px;

                ion-input {
                    //  text-align: center;
                    --padding-start: 0px;
                }
            }
        }

        img {
            height: 130px;
            margin-bottom: 20px;
        }
    }

    .step-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        font-size: 1.5rem;
        color: #6c63ff;
        background-color: #cccbea;
        border-radius: 100%;
    }
}

.breadcrumbs {
    span {
        cursor: pointer;
    }
}


.custom-btn-2 {
    height: 36px;
    width: 100%;
    color: #ffff;
    font-weight: 550;
    background: #6c63ff;
    font-family: 'roboto', sans-serif;
    font-size: 1rem;
    border-radius: 4px;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.btn-color-grey {
        background-color: rgb(238, 238, 238);
        color: #6c63ff;
    }
}

.custom-btn {
    height: 36px;
    width: 100%;
    color: #ffff;
    font-weight: 550;
    background: #6c63ff;
    font-family: 'roboto', sans-serif;
    font-size: 1rem;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.btn-color-grey {
        background-color: rgb(238, 238, 238);
        color: #6c63ff;
        border: 1px solid #e5e5e5
    }
}

.grid-btn {
    height: 36px;
    width: 100%;
    color: rgb(0, 0, 0);
    font-weight: 550;
    background: #ffecec;
    font-size: .9rem;
    border: 1px solid rgb(253, 146, 107);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}


#logout {
    p {
        text-align: center;
        color: grey;
        font-size: 0.9rem;
    }
}

#backButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #backButton {
        width: 40px;
        height: 40px;
        border: 2px solid rgb(192, 192, 192);
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        cursor: pointer;

        ion-icon {
            font-size: 1.2rem;
            color: rgb(192, 192, 192);
        }
    }
}

#allSet {
    .checkmark {
        // margin-bottom: 20px;
        background-color: #c8e6c9;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        ion-icon {
            font-size: 3rem;
            color: #4caf50;
        }
    }

    h2 {
        font-weight: 600;
        font-size: 2.3rem;
    }
}

.flex-center-xy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skip-text {
    color: grey;
    font-size: 0.9rem;
    cursor: pointer;
}

.check-container {
    display: flex;
    align-items: center;
}

.required {
    color: red;
    font-size: 0.8rem;
}

.autocomplete {
    position: relative;
}

.autocomplete-list {
    max-height: 300px;
    overflow-y: scroll;
    z-index: 5;
    position: absolute;
    width: 100%;
    margin: 0px;
    padding: 0px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 5px;

    li {
        list-style-type: none;
        padding: 0.375rem 0.75rem;
        display: flex;
        align-items: center;
        margin: 5px;
        border-radius: 4px;

        &.selected {
            background-color: var(--ion-color-primary);
            color: white;

            &:hover {
                background-color: var(--ion-color-primary);
                color: white;
                cursor: pointer;
            }
        }

        #iconContainer {
            width: 30px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                font-size: 1.2rem;
                color: var(--ion-color-secondary);
                margin-right: 10px;
            }
        }

        .main-text {
            text-align: left;
            font-size: 0.8rem;
        }

        .secondary-text {
            font-size: 0.7rem;
            text-align: left;
            color: grey;
        }

        &:hover {
            background-color: rgb(245, 245, 245);
            cursor: pointer;
        }
    }
}


.italic {
    font-style: italic;
}



.selectedAddress {
    background-color: rgb(245, 245, 245);
}

.b-c {
    color: #6c63ff !important;
    font-family: 'roboto', sans-serif;
}

.b-c-light {
    color: #b2d1fd;
}

.b-c-bg {
    background-color: #6C63FF;
}

a {
    cursor: pointer;
}

.code-font {
    font-family: 'Encode Sans', sans-serif;
    font-weight: 600;
}

.otp-box-class {
    width: 30px !important;
    height: 30px !important;
    border-color: var(--ion-color-primary) !important;
    border-width: 2px !important;
}

.vertical-align {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

a {
    text-decoration: none;
}

#questionHeader {
    margin-bottom: 20px !important;

    h6 {
        color: var(--ion-color-primary);
        font-size: 1.1rem;
        font-weight: 700;
        text-align: center;
        margin: 0;
        height: 50px;
    }

    p {
        font-size: 0.8rem;
        color: grey;
        text-align: center;
        margin: 0;
    }
}

ngx-dropzone {
    height: 100px !important;
    overflow: hidden !important;
}

.doc-item {
    ion-toggle {
        height: 15px;
    }
}

.link-over {
    text-decoration: underline;

    &:hover {
        color: rgb(255, 240, 157)
    }
}

.slide-inputs {
    width: 100%;
}

.select-option-container {
    width: 100%;
    display: inline-block;
    padding: 5px 0px;
}

.profile-item {
    cursor: pointer;
    height: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ion-icon {
        font-size: 2.5rem;
        color: #5c5c5c;
    }
}

.cal-event-action {
    color: red !important;

    a {
        color: red !important;
    }
}

.sc-ion-modal-md-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.sc-ion-modal-ios-h:last-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
}

.profile-img {
    width: 60px;
    height: 60px;
    background-size: cover;
    background-position: center;
    color: #cbcbcb;
    margin: 0 0.6rem;
    border-radius: 50%;
    border: 1px solid #cbcbcb;
}

.profile-img-2 {
    width: 160px;
    height: 160px;
    background-size: cover;
    background-position: center;
    color: #cbcbcb;
    margin: 0 0.4rem;
    border-radius: 100px;
    border: 1px solid #cbcbcb;
    margin-right: 1rem;
    object-fit: cover;
}

.profile-img-100 {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    border: 1px solid #c5c5c5;
    margin-right: 1.3rem;
    object-fit: cover;
}

.select-option {
    display: inline-block;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 6px 5px;
    font-size: 0.83rem;
    font-weight: 600;
    letter-spacing: 0.5px;

    ion-checkbox {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        min-width: 20px;
    }

    &.subanswer {
        width: 90%;
    }

    &.selected-half {
        width: 48%;
    }

    &.selected-full {
        width: 100%;
    }

    .checkbox {
        position: absolute;
        left: 10px;
        color: white;
        font-size: 1.4rem;
        top: 50%;
        translate: transform();
        transform: translate(0%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

#socialAuth {
    .social-auth-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 37px;
        border-radius: 4px;

        &:hover {
            cursor: pointer;
        }

        .hidden {
            opacity: 0;
        }

        .icon {
            width: 60px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &.apple {
            background-color: #000000;

            .text {
                color: white;

                p {
                    margin: 0;
                }
            }

            ion-icon {
                color: white;
                font-size: 1.5rem;
            }
        }

        &.google {
            margin-top: 10px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);

            img {
                width: 25px;
                margin: 0;
            }
        }

        p {
            font-size: 0.8rem;
        }

        &.linkedin {
            background-color: #0e76a8;
            margin-top: 10px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);

            .text {
                color: white;

                p {
                    margin: 0;
                }
            }

            img {
                width: 25px;
                margin: 0;
            }
        }
    }
}

.input-container {
    margin-bottom: 10px;
    border: 1px solid #0002;
    border-radius: 4px;
    position: relative;

    &.invalid-input {
        border-color: red;
    }

    .custom-label {
        position: absolute;
        padding: 0px 10px 0px 10px;
        color: rgba(0, 0, 0, 0.753);
        font-family: 'roboto', sans-serif;
        background: #ffff;
        z-index: 20;
        top: -12px;
        left: 10px;
        font-size: 0.8rem;
        position: absolute;
    }

    label {
        position: absolute;
        padding: 10px 10px 0px 10px;
        color: #0009;
        background: #ffff;
        //  transform: translate(20px, -7px);
    }

    ion-input,
    ion-textarea {
        margin: 0px 0px;
        width: 100%;
        // padding: 15px 10px;
        color: #000f;
        filter: none !important;
        text-align: left;
        //  --padding-start: 20px;
    }

    ion-datetime {
        text-align: left;
    }

    ion-input:focus {
        outline: 1px solid #0005;
    }

    ion-icon {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50%;
        font-size: 24px;
        transform: translate(0%, -50%);
        z-index: 2;
    }

    ion-icon[name='checkmark-circle-outline'] {
        color: #0f0f;
    }

    ion-icon[name='eye-outline'] {
        color: #777f;
    }
}

textarea {
    padding: 10px !important;
    min-height: 100px;
}

.input-container-2 {
    margin: 1.5rem 0;
    border: 0.5px solid #0002;
    border-radius: 4px;
    position: relative;
    background-color: white;
    flex-direction: row;
    align-items: center;

    &.invalid-input {
        border-color: red;
    }

    .custom-label {
        position: absolute;
        padding: 0px 10px 0px 10px;
        color: rgba(0, 0, 0, 0.753);
        background: #ffff;
        font-family: "roboto", sans-serif;
        font-weight: 500;
        z-index: 20;
        top: -12px;
        left: 10px;
        font-size: 0.8rem;
    }

    label {
        position: absolute;
        padding: 10px 10px 0px 10px;
        color: #0009;
        background: #ffff;
        transform: translate(20px, -7px);
    }

    ion-input,
    ion-datetime {
        margin: 0px 0px;
        width: 100%;
        padding: 7px;
        color: #000f;
        font-family: "roboto", sans-serif;
        filter: none !important;
        text-align: left;
        --padding-start: 20px;
        background-color: #fcfcff !important;
        border: 1px solid #e3e3e3;
    }

    ion-datetime {
        text-align: left;
        padding-left: 1.5rem;
        font-size: 1rem !important;
    }

    ion-input:focus {
        outline: 1px solid #0005;
    }

    ion-icon {
        font-size: 24px;
        margin: auto 1rem;
    }

    ion-icon[name="checkmark-circle-outline"] {
        color: #6c63ff;
    }

    ion-icon[name="eye-outline"] {
        color: #777f;
    }
}

@media (max-width: 991px) {
    img {
        // margin: auto;
        // display: inline-block;
    }

    a {
        text-decoration: none;
    }
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

.loading {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.w-header {
        height: calc(100% - 66px);
    }

    .lds-facebook div {
        background: var(--ion-color-primary) !important;
    }
}

.border {
    border: 1px solid #dcd6d6;
    border-radius: 7px;
}

.bord {
    border: 1px solid #dcd6d6;
}

.border-thick {
    border: 10px solid #fbfbfb;
    border-radius: 7px;
}

.width-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

.border-red {
    border: 1px solid #ff0000;
}

.border-bot {
    border-bottom: 1px solid #efefef;
    margin-bottom: .5rem;
}

.b-b {
    border-bottom: 1px solid #efefef;
}

.border-top {
    border-top: 1px solid #efefef;
}

.border-right {
    border-right: 1px solid #efefef;
}

.flex {
    display: flex;
}

.flex-rowstart {
    display: flex;
    align-items: center;
}

.flex-rowcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-rowend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-colcenter {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex-colend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.font-orange {
    color: #ff8800;
}

.cursor {
    cursor: pointer;
}

.f0-5 {
    font-size: .5rem;
}

.f0-6 {
    font-size: .6rem;
}

.f0-7 {
    font-size: .7rem;
}

.f0-8 {
    font-size: .8rem;
}

.f0-9 {
    font-size: .9rem;
}

.f1-0 {
    font-size: 1rem;
}


.f1-1 {
    font-size: 1.1rem;
}

.f1-2 {
    font-size: 1.2rem;
}

.f1-3 {
    font-size: 1.3rem;
}

.f1-4 {
    font-size: 1.4rem;
}

.f1-5 {
    font-size: 1.5rem;
}

.f1-6 {
    font-size: 1.6rem;
}

.f1-7 {
    font-size: 1.7rem;
}

.f1-8 {
    font-size: 1.8rem;
}

.f1-9 {
    font-size: 1.9rem;
}

.f2-0 {
    font-size: 2rem;
}

.f3-0 {
    font-size: 3rem;
}

.f4-0 {
    font-size: 5rem;
}

.bold-100 {
    font-weight: 100;
}

.bold-500 {
    font-weight: 500;
}

.bold-600 {
    font-weight: 600;
}

.blue {
    color: #16adff;
}

.blue-light {
    color: #e3edff;
}

.blue-bg {
    background-color: #6ea3ff;
}

.orange-bg {
    background-color: #fb9b0a;
}

.black {
    color: black;
}

.underline {
    text-decoration: underline;
}

.margin-0 {
    margin: 0;
}

.margin-left {
    margin-left: -.7rem;
}

.margin-bot-05 {
    margin-bottom: .5rem;
}

.margin-bot-07 {
    margin-bottom: .7rem;
}

.margin-bot-1 {
    margin-bottom: 1rem;
}

.margin-bot-2 {
    margin-bottom: 1rem;
}

.margin-bot-3 {
    margin-bottom: 1rem;
}

.margin-bottom-1 {
    margin-bottom: 1rem !important;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-left-1 {
    margin-left: 1rem;
}

.margin-left-2 {
    margin-left: 2rem;
}

.margin-left-3 {
    margin-left: 3rem !important;
}

.margin-left-4 {
    margin-left: 4rem;
}

.margin-left-3 {
    margin-left: 2rem;
}

.margin-right-05 {
    margin-right: .5rem;
}

.margin-left-05 {
    margin-left: .5rem;
}

.margin-right-1 {
    margin-right: 1rem;
}

.margin-right-1-5 {
    margin-right: 1.5rem;
}

.margin-right-2 {
    margin-right: 2rem;
}

.margin-05 {
    margin: .5rem;
}

.margin-1 {
    margin: 1rem;
}

.max-500 {
    max-width: 500px;
}

.max-600 {
    max-width: 600px;
}

.max-700 {
    max-width: 700px;
}

.max-800 {
    max-width: 800px;
}

.max-1000 {
    max-width: 1000px;
}

.max-1300 {
    max-width: 1300px;

}

.w-550 {
    max-width: 550px;
    margin: 0 auto;
}

.w-100 {
    width: 100% !important;
}

.w-90 {
    width: 90% !important;
}

.auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.w-700 {
    max-width: 700px;
    margin: 0 auto;
}

.center {
    text-align: center;
}

.margin-top-05 {
    margin-top: .5rem;
}

.margin-top-1-5 {
    margin-top: 1.5rem;
}

.margin-top-1 {
    margin-top: 1rem;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-top-3 {
    margin-top: 3rem;
}

.margin-top-6 {
    margin-top: 6rem;
}

.padding-1 {
    padding: 1rem;
}

.padding-top-1 {
    padding-top: 1rem;
}

.padding-2 {
    padding: 2rem;
}

.padding-0-1 {
    padding: 0 1rem;
}

.range-bar {
    height: 4px;
    background-color: #e1dddd;
    width: 100px;
    position: relative;
    border-radius: 5px;
    margin-right: 0.3rem;

    .knob-box {
        left: 30px;
        top: -5px;
        height: 15px;
        width: 11px;
        position: relative;
        background-color: orange;
        border-radius: 2px;
    }
}

.div-box {
    margin: .3rem;
    padding: .5rem;
    border: 1px solid #d1d1d1;
    background-color: white;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 2px 0px;
    border-radius: 6px;
    height: calc(100vh - 300px);
}

.inner-div-box {
    // margin-right: .3rem;
    padding: .5rem;
    background-color: white;
    box-shadow: 1px 1px 6px rgb(187, 187, 187);
    border-radius: 6px;
    height: calc(100vh - 300px);
}


.grey {
    color: rgb(134, 134, 134);
}

.grey-light {
    color: #abb2bd
}

.purple {
    color: #6963fe !important;
}

.turn {
    color: #00ffff;
}

.grey-bg {
    background-color: rgb(209, 209, 209);
}

.light-grey {
    background-color: #f0f0f8;
}

.light-grey-2 {
    background-color: #dbdbe5;
}

.border-radius {
    border-radius: 7px;
}

.grey-btn {
    --background: #6963fe;
    max-width: 150px;
    text-transform: initial;
    margin: 0.5rem;
    font-size: 0.9rem;
    color: white;
}

// ------------ scroll 

.scroll {
    overflow-x: auto;
    overflow-y: auto;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: rgba(204, 204, 204, 0.37);
    border-radius: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 0.37);
    border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(71, 108, 138);
}

.scroll-y {
    overflow-y: none !important;
}

.alink {
    color: var(--ion-color-primary);
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;

}

.grid-blue {
    color: #16adff;
    text-decoration: underline;
    //   font-weight: bold;
}

.small-font {
    font-size: .8rem;
    color: red;
}

.flex-btw {
    display: flex;
    justify-content: space-between;
}

.flex-btwcenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-aroundcenter {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.package-type {
    font-size: 1.3rem !important;
}

.select-list {
    background-color: #fcfcfc;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d5d4d4;
    padding: 0.2rem;
    min-width: 130px;
}

.center-modal-2 {
    --width: 1200px;
    --height: 90vh !important;
}

.cap {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.right-3 {
    right: 3rem
}

.right {
    text-align: right
}

.center-logo {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-start {
    display: flex;
    align-items: flex-start;
}

.flex-h {
    display: flex;
    align-items: center;
}

.icon-14 {
    font-size: 1.4rem;
}

.red {
    color: red
}

.white-bg {
    background-color: white;
}

.white {
    color: white;
}

.radius {
    border-radius: 6px;
}

.alert {
    // background-color: #efefef;
    padding: 0 0.3rem;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 0.85rem;
    color: red;
    margin-top: 0.6rem;
    width: 100%;

    ion-icon {
        font-size: 1.5rem;
        margin-right: .3rem;
        color: #fb0a0a !important
    }
}

.left {
    text-align: left;
}

.rec2 {
    background-color: #f7f7ff;
    border-radius: 0 4px 4px 0;
    padding: 0.8rem;
    margin-bottom: 1.6rem;
    cursor: pointer;
    box-shadow: 2px 2px 2px #e7e7e7;
    color: black;
    font-weight: 500;
    font-size: 0.95rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d7d7d7;

    ion-icon {
        font-size: 1.5rem;
    }
}


.card-selected {
    // background-color: #6c63ff;
    // color: white;
    border-left: 6px solid #6c63ff;
}

.my-modal-class-2 {
    --width: 100%;
    --height: 100%;
    padding: 6rem;
    justify-content: center;
    align-items: center;

    .modal-wrapper {
        border-radius: 20px !important;
    }
}

@media (max-width: 972px) {
    .my-modal-class-2 {
        --max-width: 1300px;
        --width: 96% !important;
        --height: 96% !important;
        justify-content: unset !important;
        align-items: unset !important;
        padding: 1rem;
    }
}

.cart-icon {
    background-color: red;
    color: white;
    font-size: 0.75rem;
    border-radius: 50%;
    padding: 0.2rem;
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -0.25rem;
    right: -0.5rem;
}

:host {
    --box-shadow: unset !important;
}

.bg-color {
    background-color: #6C63FF;
}

.bg-white {
    background-color: #ffffff;
}

.content-bg {
    --background: #f4f4fa;
}

.opacity-50 {
    opacity: .5
}

.opacity-100 {
    opacity: 1
}

.yellow {
    color: yellow !important
}

.yellow_box {
    background: #fff2be;
    width: fit-content;
    padding: 2px 5px;
    font-size: 0.9rem;
    border: 1px solid #e1d7b0;
    min-width: 240px;
    border-radius: 5px;
    min-height: 30px;
    display: flex;
    align-items: center;
}

.yel-box-2 {
    border-radius: 3px;
    background: #fdd84a;
    padding: .6rem;
    width: 98%;
    margin-top: 0.85rem;
    font-size: 0.9rem;
}

.yel-box-3 {
    border-radius: 6px;
    background: #dddddd;
    padding: 0.5rem;
    width: 98%;
    margin-top: 0.85rem;
    font-size: .9rem;
}

.grey_box {
    background: #ececec;
    padding: 2px 5px;
    font-size: 0.9rem;
    border: 1px solid #cecece;
    min-width: 200px;
    border-radius: 5px;
    min-height: 30px;
    display: flex;
    align-items: center;
}

.li-icon {
    font-size: 1.5rem;
    border-radius: 50%;
    padding: 0;
    font-weight: bold;
    margin: 0 0 0 1rem;
    //   background-color: white;
}

.in-put {
    border: 1px solid grey;
    padding: 0 0.2rem;
    margin-right: 0.3rem;
    border-radius: 5px;
    height: 38px;
}

ion-content {
    --background: #fff;
}

.pie {
    border: 2px solid white;
    box-shadow: 0 0 3px #4f4c4c;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: .2rem;
}

.o-s {
    margin: 2rem auto 0 auto;
    max-width: 1300px;
    font-size: 1.3rem;
    text-align: left;
    font-weight: 600;
}

.main-container {
    // width: calc(100vw - 200px);
    // margin: 1rem auto;
    padding-left: 250px;
    // max-width: 1300px;
}

.top-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #9398a6;

    ion-icon {
        font-size: 1.5rem;
        color: #9398a6;
        margin-right: 1rem;
    }

    &:hover {
        background-color: white;
        border-radius: 7px;
        color: var(--ion-color-primary);
    }

}

.lh1-6 {
    line-height: 1.6rem;
}

.social {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;

    img {
        width: 35px;
        height: 35px;
    }
}

.hide-img {
    width: 20px
}

.ii {
    display: flex;
    align-items: center;

    div {
        ion-icon {
            font-size: 2rem;
            color: var(--ion-color-primary);
            margin-right: 0.3rem;
        }
    }
}

// styling from user app
.main-div {
    display: flex;
    height: 88vh;
    overflow: hidden;
}

.main-section {
    // width: 100%;
    // max-width: 2000px;
    // padding-left: 250px;
    min-height: calc(100vh - 140px);
    // margin-top: 1.5rem;
    // margin-bottom: 3rem;
    overflow-y: scroll;
    width: -webkit-fill-available;
    max-width: 1600px;
    padding: 1rem;
}

.main-section2 {
    width: 100%;
    max-width: 1500px;
    padding-left: 60px;
    margin: 2rem auto
}

.main-section3 {
    width: 100%;
    margin: 1rem auto
}


.main-sec {
    max-width: 1400px;
    width: 600px;
    // min-height: 80vh;
    margin-bottom: 2rem;
}

@media(max-width: 972px) {
    .main-sec {
        width: 98%;
        margin: .5rem auto
    }
}

.dash-img {
    display: inline-block;
    width: 35px !important;
    height: 35px !important;
    max-width: unset;
    margin-right: 0.25rem;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #a3a3a3;
}

.dash-img-2 {
    width: 145px !important;
    height: 145px !important;
    margin-right: 0.6rem;
    border-radius: 50%;
}

.dash-img-3 {
    width: 45px !important;
    margin-right: 0.6rem;
    border-radius: 50%;
}

.dash-img-4 {
    display: inline-block;
    width: 45px !important;
    height: 45px !important;
    max-width: unset;
    margin-right: 0.25rem;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #a3a3a3;
    margin-right: .4rem;
}

.tax_img {
    width: 35px;
    height: 40px;
    margin-right: 0.3rem;
    opacity: .8;
}

//--- -define flex---

.flex {
    display: flex;
}

.iflex {
    display: inline-flex;
}

.f-row {
    flex-direction: row;
}

.f-col {
    flex-direction: column;
}

.f-just-center {
    justify-content: center;
}

.f-just-start {
    justify-content: flex-start;
}

.f-just-end {
    justify-content: flex-end;
}

.f-just-btw {
    justify-content: space-between;
}

.f-just-around {
    justify-content: space-around;
}

.f-just-evenly {
    justify-content: space-evenly;
}

.f-align-center {
    align-items: center;
}

.f-align-start {
    align-items: flex-start;
}

.f-align-end {
    align-items: flex-end;
}

.f-align-base {
    align-items: baseline;
}

.f-wrap {
    flex-wrap: wrap;
}

.f-nowrap {
    flex-wrap: nowrap;
}


//---------------------------------------------------------------


.main-section-full {
    width: calc(100% - 90px);
    margin-left: 90px;
    height: 100%;
}

// @media(max-width: 972px) {
//     .main-section {
//         width: 98%;
//         margin: .5rem auto
//     }
// }

.max-200px {
    max-width: 200px;
    margin: 0 auto;
}

._card-top {
    border-radius: 7px 7px 0 0;
    background-color: white;
    padding: .4rem;
}

._card-bot {
    border-radius: 0 0 7px 7px;
    background-color: white;
    padding: 1rem;
}

._card {
    min-height: 320px;
    max-height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 2px 2px 5px #c3c3c3;
    background-color: white;
    padding: 1rem;
}

._card-radius {
    min-height: 300px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 2px 2px 5px #c3c3c3;
    border-radius: 7px;
    background-color: white;
    padding: 1rem;
}

._card-radius2 {
    box-shadow: 2px 2px 5px #c3c3c3;
    border-radius: 7px;
    background-color: white;
    padding: 1rem;

}

// twilion chat ------------------------------------------------
.chat-rec {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dbdbdb;
    padding: .5rem 0 0.5rem 0;
    min-height: 66px;

    .ms-2 {
        .date-time {
            font-size: 12px;
            color: var(--ion-color-primary);
            width: 67px;
            text-align: right;
            margin-right: .35rem;
        }

        .chat-msg {
            font-size: .9rem;
            color: #a5a5a5
        }
    }

    div {
        .ms-2 {
            .date-time {
                font-size: 12px;
                color: var(--ion-color-primary);
                width: 67px;
                text-align: right;
            }

            .chat-msg {
                font-size: .9rem;
                color: #a5a5a5
            }
        }
    }

    &.active {
        font-family: 'roboto-medium';
        background-color: #f1f1fb !important;

        .ms-2 {
            .date-time {
                // color: yellow !important;
            }

            .chat-msg {
                //   color: yellow !important;
            }
        }

        div {
            .ms-2 {
                .date-time {
                    // color: yellow !important;
                }

                .chat-msg {
                    //  color: yellow !important;
                }
            }
        }
    }
}

.profile {
    width: 35px;
    height: 35px;
    background: #a1a1a9;
    border-radius: 50%;
    color: white;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    img {
        border-radius: 50%;
        height: 35px;
        width: 55px;
        object-fit: cover;
        border: 1px solid #e5dddd;
    }
}

.dot-icon {
    position: absolute;
    left: 2rem;
    bottom: -0.3rem;
    font-size: 1rem !important;
    z-index: 100;
}

.bell-icon {
    font-size: 1.4rem;
}

.msg-alert {
    background-color: #ff0000;
    color: white;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .2rem;
    font-size: 0.8rem;
}

.msg-alert2 {
    background-color: #ff0000;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.2rem;
    font-size: 0.8rem;
}

.chat-header {
    background-color: #3d4a5d;
    color: white;
    border-radius: 5px 5px 0 0;
    padding: 1rem 0.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
}

.abr {
    font-size: 1.07rem;
    background-color: #4f5f7717;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    margin-right: 0.4rem;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid #707d91;
    width: 57px;

    img {
        border-radius: 5px;
        object-fit: cover;
    }
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.d-flex {
    display: flex !important;
}

.input-wrapper.sc-ion-input-md {
    display: unset !important;
}

input {
    height: 44px;
    padding-left: 2.2rem !important;
}

.camera {
    position: absolute;
    z-index: 1000;
    bottom: 0rem;
    right: 1rem;
    font-size: 2rem;
    color: #ababab;
    background-color: white;
    border-radius: 50%;
    padding: 0.21rem;
    border: 1px solid #b3b3b3;
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.quill-edit {
    width: 100%;
    height: 200px;
}

.left-marg-70 {
    margin-left: 70px;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     -webkit-box-shadow: 0 0 0 30px white inset !important;
//     color: black !important;
// }

.slider-section {
    background-color: white;
    border-radius: 6px;
}


.main-head {
    color: #6C63FF;
    // padding: 0 20px;
    font-size: 1.5rem !important;
    font-weight: bold;
    text-transform: capitalize;
}

.robo-thin {
    font-family: 'roboto-thin';
}

.robo {
    font-family: 'roboto';
}

.robo-medium {
    font-family: 'roboto-medium';
}

.robo-black {
    font-family: 'roboto-medium';
}

.btn-primary {
    --background: #6c63ef !important;
    --box-shadow: rgb(0 0 0 / 0%) 0px 0px 2px 0px;
    --color: #fff !important;
    --border-radius: 10px !important;
    text-transform: capitalize;
    border: none !important;
    width: fit-content;
}

.btn-sec {
    --background: #efefef !important;
    --box-shadow: rgb(0 0 0 / 0%) 0px 0px 2px 0px;
    --color: #000 !important;
    --border-radius: 10px !important;
    text-transform: capitalize;
    border: none !important;
    width: fit-content;
}

.incr {
    padding: 5px;
    border: 2px solid #C0F4D2;
    background-color: #EAFFF1;
    color: #40BD6B;
    border-radius: 5px;
    min-width: 70px;
}

ion-popover {
    --border-radius: 20px;
    --width: 20rem;

    ion-content:nth-child(8) {

        .top-menu {
            justify-content: center;
            border: 1px solid #9398a6;
            padding: 5px;
            border-radius: 10px;
        }
    }
}

.status {
    padding: 5px;
    border: 2px solid #C0F4D2;
    background-color: #EAFFF1;
    color: #40BD6B;
    border-radius: 5px;
}

.not-status {
    padding: 5px;
    border: 2px solid #e60000;
    background-color: #e600000e;
    color: #e60000;
    border-radius: 5px;
}

.unread {
    background: #6e65ff;
    height: 25px;
    width: 25px;
    border-radius: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

/* Hide scrollbar for all elements */
::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background: #888;
    /* Scrollbar color */
    border-radius: 4px;
    /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Scrollbar color on hover */
}

.service-tags {
    height: 115px;
    overflow-y: scroll;
}

.my-card {
    border-radius: unset;
    background-color: unset;
    margin: 0.1rem 0;
    box-shadow: unset;
    //  border-bottom: 1px solid rgb(204, 204, 204);
}

@media (max-width: 768px) {

    .my-card {
        border-radius: 5px;
        background-color: white;
        margin: 0.1rem 0 .1rem 0;
        //   min-height: 110px;
        box-shadow: 2px 2px 4px #d8d8d8;
    }
}

@media (min-width: 500px) and (max-width: 768px) {

    /* Target mobile screens */
    .hide-md {
        display: none;
    }

    .main-section {
        padding-left: 0px;
    }

    .flex-md-col {
        flex-direction: column;
    }

    .text-md-center {
        text-align: center;
    }

    .flex-md-just-center {
        justify-content: center;
    }

    .margin-md-0 {
        margin: 0 !important;
    }

    .padding-md-0 {
        padding: 0 !important;
    }


}

@media (max-width: 499px) {

    /* Target mobile screens */
    .hide-sm {
        display: none;
    }

    .main-section {
        padding-left: 0px;
    }

    .alert {
        flex-direction: column;
        align-items: start;
    }

    .flex-sm-col {
        flex-direction: column;
    }

    .text-sm-center {
        text-align: center;
    }

    .flex-sm-just-center {
        justify-content: center;
    }

    .margin-sm-0 {
        margin: 0 !important;
    }

    .padding-sm-0 {
        padding: 0 !important;
    }

    .width-sm-100 {
        width: 100% !important;
    }

    .status,
    .not-status {
        font-size: 12px;
    }

    .margin-l-sm-0 {
        margin-left: 0 !important;
    }

    .list-sm-unstyled {
        list-style: none;
    }
}

.searchBar {
    --border-radius: 10px;
    --box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

ion-progress-bar {
    border-radius: 100px !important;
}

.lead-set {
    padding: 1rem 2rem;
    height: 100%;
    background-color: #f6f6f9;
}

.number-img {
    background-color: #b9f3fb;
    color: #00a5bf;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    font-size: 1rem;
    border-radius: 50%;
}